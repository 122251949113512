(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/competition-error-codes/assets/javascripts/competition-error-codes.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/competition-error-codes/assets/javascripts/competition-error-codes.js');

'use strict';




const parseErrorCode = rawCode => {
  let parsedCode = null;
  const parts = (rawCode === null || rawCode === void 0 ? void 0 : rawCode.toString().split(' ')) || [];
  if (parts.length > 1) {
    parsedCode = parts[parts.length - 1];
  } else if (parts.length) {
    parsedCode = parts[0];
  }
  parsedCode = parseInt(parsedCode, 10);
  if (isNaN(parsedCode)) {
    throw new Error("Error code couldn't be parsed");
  }
  return parsedCode;
};

const processErrorCode = function () {
  let error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const defaultTitle = 'Tekniskt fel';
  const defaultMessage = 'Försök igen senare.';
  let errorCode;
  let errorMessage;
  let errorTitle = 'Något gick fel';
  if (error.code) {
    errorCode = parseErrorCode(error.code);
  }
  switch (errorCode) {
    case 3: 
    case 103: 
    case 104:
      errorTitle = defaultTitle;
      errorMessage = defaultMessage;
      break;
    case 102:
      errorTitle = 'Tävlingen har redan startat';
      errorMessage = null;
      break;
    case 105:
      errorTitle = 'Du är inte anmäld till tävlingen';
      errorMessage = 'Du behöver anmäla dig för att vara med i tävlingen.';
      break;
    case 106:
      errorTitle = 'Anmälan har inte öppnat än';
      errorMessage = 'Kika förbi lite senare.';
      break;
    case 107:
      errorTitle = 'Du kan inte vara med i fler lag';
      errorMessage = 'Du kan som mest vara med i 5 lag i tävlingen.';
      break;
    case 108:
      errorTitle = 'Lagnamnet är upptaget';
      errorMessage = 'Välj ett annat lagnamn.';
      break;
    case 109:
      errorTitle = 'Inbjudningslänken är inte längre giltig';
      errorMessage = 'Be lagkaptenen att bjuda in dig.';
      break;
    case 110:
      errorTitle = 'Du är redan medlem i det här laget';
      errorMessage = null;
      break;
    case 111:
      errorTitle = 'Laget är redan fullt';
      errorMessage = 'Du kan alltid skapa ett eget lag.';
      break;
    case 112:
      errorTitle = 'Laget finns inte längre';
      errorMessage = 'Du kan alltid skapa ett eget lag.';
      break;
    default:
      errorTitle = defaultTitle;
      errorMessage = defaultMessage;
  }
  return {
    errorCode,
    errorMessage,
    errorTitle
  };
};
if (svs.isServer) {
  module.exports = {
    parseErrorCode,
    processErrorCode
  };
} else {
  setGlobal('svs.components.marketplaceShared.competitionErrorCodes.parseErrorCode', parseErrorCode);
  setGlobal('svs.components.marketplaceShared.competitionErrorCodes.processErrorCode', processErrorCode);
}

 })(window);